import React from 'react';
import './style.scss';

const RethinkLogo = (props) => {

    return (
        // <svg className="rethink-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"   x="0px" y="0px"
        //     viewBox="0 0 94.31 94.95" xmlSpace="preserve">
       
        //     <g>
        //         <g>
        //             <path className="st0" d="M94.31,47.41c0,26.21-20.94,47.54-47.03,47.54S0,73.62,0,47.41C0,21.33,21.2,0,47.28,0
        //                 C73.37,0,94.31,21.33,94.31,47.41z M83.78,47.41c0-21.2-15.93-37.65-36.49-37.65c-20.43,0-36.49,16.45-36.49,37.65
        //                 c0,21.33,16.06,37.78,36.49,37.78C67.84,85.19,83.78,68.74,83.78,47.41z M39.19,72.85H26.6l10.92-19.91
        //                 c-6.3-2.44-10.54-8.1-10.54-15.16c0-9.12,7.58-16.45,17.6-16.45h19.15v51.52H52.68v-18.5h-3.73L39.19,72.85z M52.68,44.97V30.71
        //                 h-6.3c-5.14,0-8.1,2.7-8.1,7.19c0,4.37,2.96,7.07,8.1,7.07H52.68z"/>
        //         </g>
        //     </g>
        // </svg>

        <svg version="1.1" className="rethink-logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 84.15 84.15" xmlSpace="preserve">
     
            <g>
                <path class="st0" d="M34.44,42.21c-5.15-1.82-8.54-5.66-8.54-11.37c0-5.95,3.13-13.04,14.19-13.04h15.96v45.43c0,0-7.72,0-8.41,0
                c0-0.77,0-19.15,0-19.15h-5.25c0,0-10.01,18.69-10.21,19.15c-0.46,0-8.61,0-9.69,0C23.02,62.14,34.44,42.21,34.44,42.21z
                    M41.22,24.56c-4.26,0-7.33,2.14-7.33,6.28c0,4.27,3.42,6.34,8.34,6.34h5.41V24.56H41.22z"/>
                <g>
                    <g>
                        <path class="st0" d="M84.15,42.08c0,23.24-18.84,42.07-42.08,42.07C18.84,84.15,0,65.32,0,42.08C0,18.84,18.84,0,42.08,0
                            C65.32,0,84.15,18.84,84.15,42.08z M77.24,42.08c0-19.4-15.76-35.16-35.16-35.16c-19.4,0-35.16,15.76-35.16,35.16
                            c0,19.4,15.76,35.16,35.16,35.16C61.47,77.24,77.24,61.48,77.24,42.08z"/>
                    </g>
                    <g>
                        <path class="st0" d="M84.15,42.08c0,23.24-18.84,42.07-42.08,42.07C18.84,84.15,0,65.32,0,42.08C0,18.84,18.84,0,42.08,0
                            C65.32,0,84.15,18.84,84.15,42.08z M77.24,42.08c0-19.4-15.76-35.16-35.16-35.16c-19.4,0-35.16,15.76-35.16,35.16
                            c0,19.4,15.76,35.16,35.16,35.16C61.47,77.24,77.24,61.48,77.24,42.08z"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}
export default RethinkLogo;