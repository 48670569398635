import React, { useState, useEffect, createContext, useContext } from 'react';
import {Helmet} from 'react-helmet';

const LanguageContext = createContext();


const defaultLanguage = 'en';
const LanguageContextProvider = ({ children }) => {
    
    const [lang, setLang] = useState(defaultLanguage);

    useEffect(() => { 
        
        // Default to en
        let language = defaultLanguage;
        let m = null;
        if ((m = /^\/([a-z]{2})[\/.*]?/.exec(document.location.pathname)) !== null) {
            language = m[1];

            // If is not EN or FR, hardcode to EN to ensure some text displays. 
            // Add or adjust for more languages. 

            if (language !== 'fr'){
                language = 'en'
            }
        }
        setLang(language);

        
    }, []);
    

    return (

        <>
            <Helmet htmlAttributes={{ lang: lang }} />
            
            <LanguageContext.Provider value={{lang, setLang}}>
                {children}
            </LanguageContext.Provider>

        </>

    )
}
export { LanguageContext, LanguageContextProvider }

// Set string/value of text to match currently selected language.
export const TranslatableText = props => {
    
    const langContext = useContext(LanguageContext);
   // console.log('transtext lang', langContext);
    return (
        <>
            {props.dictionary[langContext.lang]}
        </>
    )
};