import React from 'react';
import './style.scss';

const IndependentsWeekLogo = (props) => {

    return (
           <svg version="1.1" className="independents-week-logo"  id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 480.04 142.64"  xmlSpace="preserve">
            <g>
                <g>
                    <g>
                        <path class="st0" d="M0,56.92V1.18h9.59v55.74H0z"/>
                        <path class="st0" d="M29.08,56.92h-9.12V18.63h8.88v5.11c2.52-4.4,7.08-6.21,11.32-6.21C49.52,17.53,54,24.29,54,32.7v24.21
                            h-9.12V34.28c0-4.72-2.12-8.49-7.86-8.49c-5.19,0-7.94,4.01-7.94,9.04V56.92z"/>
                        <path class="st0" d="M98.89,49.92c0,3.46,0.31,6.37,0.39,7h-8.73c-0.16-0.86-0.39-3.38-0.39-4.95c-1.81,3.22-5.82,5.9-11.24,5.9
                            c-11.01,0-18.4-8.65-18.4-20.2c0-11.01,7.47-20.05,18.24-20.05c6.68,0,10.06,3.07,11.24,5.5V0h8.88V49.92z M79.95,49.84
                            c5.97,0,10.14-4.95,10.14-12.26S86,25.71,80.03,25.71c-5.97,0-10.38,4.64-10.38,11.95S73.74,49.84,79.95,49.84z"/>
                        <path class="st0" d="M142.75,45.99c-2.04,6.68-8.1,12.11-17.3,12.11c-10.38,0-19.58-7.55-19.58-20.52
                            c0-12.11,8.96-20.13,18.63-20.13c11.79,0,18.71,7.78,18.71,19.89c0,1.49-0.16,2.75-0.24,2.91H115c0.24,5.82,4.8,9.98,10.46,9.98
                            c5.5,0,8.33-2.91,9.59-6.68L142.75,45.99z M134.02,33.57c-0.16-4.48-3.14-8.49-9.43-8.49c-5.74,0-9.04,4.4-9.36,8.49H134.02z"/>
                        <path class="st0" d="M150.31,71.86V18.63h8.81v5.19c1.89-3.3,6.29-6.05,12.19-6.05c11.32,0,17.69,8.65,17.69,19.97
                            c0,11.48-7,20.13-18,20.13c-5.5,0-9.67-2.36-11.64-5.27v19.26H150.31z M169.57,25.87c-5.97,0-10.3,4.72-10.3,11.87
                            c0,7.31,4.32,12.03,10.3,12.03c6.05,0,10.3-4.72,10.3-12.03C179.87,30.58,175.7,25.87,169.57,25.87z"/>
                        <path class="st0" d="M230.36,45.99c-2.04,6.68-8.1,12.11-17.3,12.11c-10.38,0-19.58-7.55-19.58-20.52
                            c0-12.11,8.96-20.13,18.63-20.13c11.79,0,18.71,7.78,18.71,19.89c0,1.49-0.16,2.75-0.24,2.91h-27.99
                            c0.24,5.82,4.8,9.98,10.46,9.98c5.5,0,8.33-2.91,9.59-6.68L230.36,45.99z M221.64,33.57c-0.16-4.48-3.14-8.49-9.43-8.49
                            c-5.74,0-9.04,4.4-9.36,8.49H221.64z"/>
                        <path class="st0" d="M247.04,56.92h-9.12V18.63h8.88v5.11c2.52-4.4,7.08-6.21,11.32-6.21c9.36,0,13.84,6.76,13.84,15.17v24.21
                            h-9.12V34.28c0-4.72-2.12-8.49-7.86-8.49c-5.19,0-7.94,4.01-7.94,9.04V56.92z"/>
                        <path class="st0" d="M316.85,49.92c0,3.46,0.31,6.37,0.39,7h-8.73c-0.16-0.86-0.39-3.38-0.39-4.95c-1.81,3.22-5.82,5.9-11.24,5.9
                            c-11.01,0-18.4-8.65-18.4-20.2c0-11.01,7.47-20.05,18.24-20.05c6.68,0,10.06,3.07,11.24,5.5V0h8.88V49.92z M297.91,49.84
                            c5.97,0,10.14-4.95,10.14-12.26s-4.09-11.87-10.06-11.87s-10.38,4.64-10.38,11.95S291.7,49.84,297.91,49.84z"/>
                        <path class="st0" d="M360.71,45.99c-2.04,6.68-8.1,12.11-17.3,12.11c-10.38,0-19.58-7.55-19.58-20.52
                            c0-12.11,8.96-20.13,18.63-20.13c11.79,0,18.71,7.78,18.71,19.89c0,1.49-0.16,2.75-0.24,2.91h-27.99
                            c0.24,5.82,4.8,9.98,10.46,9.98c5.5,0,8.33-2.91,9.59-6.68L360.71,45.99z M351.98,33.57c-0.16-4.48-3.14-8.49-9.43-8.49
                            c-5.74,0-9.04,4.4-9.36,8.49H351.98z"/>
                        <path class="st0" d="M377.38,56.92h-9.12V18.63h8.88v5.11c2.52-4.4,7.08-6.21,11.32-6.21c9.36,0,13.84,6.76,13.84,15.17v24.21
                            h-9.12V34.28c0-4.72-2.12-8.49-7.86-8.49c-5.19,0-7.94,4.01-7.94,9.04V56.92z"/>
                        <path class="st0" d="M422.94,18.63h7.94v8.1h-7.94V44.5c0,3.38,1.49,4.8,4.87,4.8c1.26,0,2.75-0.24,3.14-0.31v7.55
                            c-0.55,0.24-2.28,0.86-5.58,0.86c-7.08,0-11.48-4.25-11.48-11.4V26.73h-7.08v-8.1h1.97c4.09,0,5.9-2.59,5.9-5.97V6.92h8.25V18.63
                            z"/>
                        <path class="st0" d="M443.24,0.55c3.77,0,6.53,2.99,6.53,7.31c0,10.22-6.84,14.15-11.64,14.62v-4.32
                            c3.22-0.63,6.13-3.7,6.29-7.23c-0.16,0.08-0.86,0.39-1.65,0.39c-3.14,0-5.35-2.2-5.35-5.42C437.43,3.07,439.86,0.55,443.24,0.55z
                            "/>
                        <path class="st0" d="M457.72,44.34c0.31,3.46,2.91,6.52,7.78,6.52c3.77,0,5.74-2.04,5.74-4.4c0-2.04-1.42-3.62-4.48-4.25
                            l-5.66-1.26c-6.76-1.49-10.3-5.9-10.3-11.16c0-6.68,6.13-12.34,14.07-12.34c10.69,0,14.15,6.92,14.78,10.69l-7.7,2.2
                            c-0.31-2.2-1.97-5.74-7.08-5.74c-3.22,0-5.5,2.04-5.5,4.4c0,2.04,1.49,3.54,3.85,4.01l5.82,1.18
                            c7.23,1.57,11.01,5.98,11.01,11.64c0,5.82-4.72,12.26-14.47,12.26c-11.09,0-15.33-7.23-15.8-11.56L457.72,44.34z"/>
                    </g>
                    <g>
                        <path class="st0" d="M69.46,85.72h9.75l-15.64,55.74h-9.75L40.3,99.32l-13.52,42.14H17.1L1.3,85.72h9.91l11.16,40.72l13.05-40.72
                            h9.83l13.29,41.04L69.46,85.72z"/>
                        <path class="st0" d="M115.92,130.53c-2.04,6.68-8.1,12.11-17.3,12.11c-10.38,0-19.58-7.55-19.58-20.52
                            c0-12.11,8.96-20.13,18.63-20.13c11.79,0,18.71,7.78,18.71,19.89c0,1.49-0.16,2.75-0.24,2.91H88.17
                            c0.24,5.82,4.8,9.98,10.46,9.98c5.5,0,8.33-2.91,9.59-6.68L115.92,130.53z M107.2,118.11c-0.16-4.48-3.14-8.49-9.43-8.49
                            c-5.74,0-9.04,4.4-9.36,8.49H107.2z"/>
                        <path class="st0" d="M157.89,130.53c-2.04,6.68-8.1,12.11-17.3,12.11c-10.38,0-19.58-7.55-19.58-20.52
                            c0-12.11,8.96-20.13,18.63-20.13c11.79,0,18.71,7.78,18.71,19.89c0,1.49-0.16,2.75-0.24,2.91h-27.99
                            c0.24,5.82,4.8,9.98,10.46,9.98c5.5,0,8.33-2.91,9.59-6.68L157.89,130.53z M149.16,118.11c-0.16-4.48-3.14-8.49-9.43-8.49
                            c-5.74,0-9.04,4.4-9.36,8.49H149.16z"/>
                        <path class="st0" d="M185.57,119.37l16.04,22.09h-11.16l-11.24-15.64l-4.72,4.95v10.69h-9.04V84.54h9.04v34.12l14.7-15.49h12.11
                            L185.57,119.37z"/>
                    </g>
                    <g>
                        <path class="st0" d="M213.29,95.72c-1.43-0.5-2.37-1.57-2.37-3.16c0-1.65,0.87-3.62,3.94-3.62h4.43v12.63c0,0-2.15,0-2.34,0
                            c0-0.21,0-5.32,0-5.32h-1.46c0,0-2.78,5.19-2.84,5.32c-0.13,0-2.39,0-2.69,0C210.12,101.26,213.29,95.72,213.29,95.72z
                                M215.17,90.82c-1.18,0-2.04,0.59-2.04,1.75c0,1.19,0.95,1.76,2.32,1.76h1.5v-3.51H215.17z"/>
                        <g>
                            <g>
                                <path class="st0" d="M227.11,95.69c0,6.46-5.23,11.69-11.69,11.69c-6.46,0-11.69-5.23-11.69-11.69c0-6.46,5.24-11.7,11.69-11.7
                                    C221.87,83.99,227.11,89.23,227.11,95.69z M225.18,95.69c0-5.39-4.38-9.77-9.77-9.77c-5.39,0-9.77,4.38-9.77,9.77
                                    c0,5.39,4.38,9.77,9.77,9.77C220.8,105.46,225.18,101.08,225.18,95.69z"/>
                            </g>
                            <g>
                                <path class="st0" d="M227.11,95.69c0,6.46-5.23,11.69-11.69,11.69c-6.46,0-11.69-5.23-11.69-11.69c0-6.46,5.24-11.7,11.69-11.7
                                    C221.87,83.99,227.11,89.23,227.11,95.69z M225.18,95.69c0-5.39-4.38-9.77-9.77-9.77c-5.39,0-9.77,4.38-9.77,9.77
                                    c0,5.39,4.38,9.77,9.77,9.77C220.8,105.46,225.18,101.08,225.18,95.69z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
export default IndependentsWeekLogo;