import { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useIsMobileView from 'hooks/useIsMobileView';
import Home from 'pages/Home/Home';
import Example from 'pages/Example/Example';
import ComingSoon from 'pages/ComingSoon/ComingSoon';
import SigninExample from 'pages/Signin-example/Signin-example';
import { LanguageContext } from './context/LanguageContext/LanguageContext';
import 'assets/scss/styles.scss';

// .env variables can be access like the following
// const AWS_POINT = process.env.REACT_APP_AWS_POINT;

// uncomment below if coming soon page is needed
// import ComingSoon from './pages/ComingSoon/ComingSoon';

// Add translation functions

// Add Lazy loading example
// Add UI stuff, modal, forms 
 
const App = () => {
    const COMING_SOON = process.env.REACT_APP_COMING_SOON;

    //const [lang, setLang] = useState();
    const langContext = useContext(LanguageContext);
    const [isMobile, setIsMobile] = useState(true);
    // Use our custom hook for viewports.
    const isMobileView = useIsMobileView();
    // Use our custom hook for language. 
    //const getLang = useGetLang();
    
    useEffect(() => {
        // Set language
        //setLang(getLang);
        // Set Desktop or Mobile viewport 
        setIsMobile(isMobileView);
    
        return () => {
            console.log('cleanUp App.js');
        }
        
    },[isMobileView]);


    const comingSoonPage = <Routes>
                                <Route path="/:lang" element={<ComingSoon lang={langContext.lang} isMobile={isMobile} />}></Route>
                            </Routes>
                            
    const app = <Routes>
                    <Route path="/" element={<Home lang={langContext.lang} isMobile={isMobile} />}></Route>
                    <Route path="/:lang" element={<Home lang={langContext.lang} isMobile={isMobile} />}></Route>

                    {/* <Route path="/:lang/example" element={<Example lang={langContext.lang} isMobile={isMobile} />}></Route>
                    <Route path="/:lang/example" element={<Example lang={langContext.lang} isMobile={isMobile} />}></Route>
                    <Route path="/:lang/signin-example" element={<SigninExample  lang={langContext.lang} isMobile={isMobile} />}></Route> */}
                </Routes>


    return (
    
        <BrowserRouter>
            {COMING_SOON === "true" && comingSoonPage}
            {COMING_SOON !== "true" && app}
        </BrowserRouter>

    );
}

export default App;
