import React, {useState} from 'react';
import './style.scss';
//import Modal from 'components/UI/Modal/Modal';
//import useToggle from 'hooks/useToggle';
import CalendarIcon from 'components/SvgIcons/CalendarIcon/CalendarIcon';
import { TranslatableText } from '../../context/LanguageContext/LanguageContext';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import Linkedin from 'components/SocialButtons/icons/Linkedin/Linkedin';
import Twitter from 'components/SocialButtons/icons/Twitter/Twitter';
import Facebook from 'components/SocialButtons/icons/Facebook/Facebook';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import { LinkedinShareButton } from 'react-share';
import { ICalendar, GoogleCalendar, OutlookCalendar } from 'datebook';
import RethinkLogo from 'components/SvgIcons/RethinkLogo/RethinkLogo';
import IndependentsWeekLogo from 'components/SvgIcons/IndependentsWeekLogo/IndependentsWeekLogo';


const Home = (props) => {
    const {lang, isMobile} = props;
    //const [showModal, setShowModal] = useState(false);
    // const [isToggled, toggle] = useToggle(false);
    let tweetCopy1 = `Let%E2%80%99s%20take%20a%20break%20this%20summer.%20Burnout%20is%20bad%20for%20business.%20That%E2%80%99s%20why%20we%E2%80%99re%20taking%20a%20week%20off%20during%20summer%20from%20now%20on.%20Think%20your%20agency%20could%20use%20the%20break%3F%20Go%20to%20independentsweek.co%20to%20add%20it%20to%20your%20calendar.%20&url=https://www.independentsweek.co%20pic.twitter.com/SebGBn2pXN`;
    let tweetCopy2 = `The%20ad%20industry%20deserves%20a%20summer%20break.%20That%E2%80%99s%20why%20I%20support%20the%20campaign%20for%20an%20industry-wide%20week%20off.%20People%20do%20better%20work%20when%20they%E2%80%99re%20rested%20and%20recharged.%20Go%20to%20independentsweek.co%20to%20add%20a%20break%20to%20your%20calendar.%20&url=https://www.independentsweek.co%20pic.twitter.com/SebGBn2pXN`;
    //console.log('home props ', props);

    const options: CalendarOptions = {
            title: "Independent's Week",
            description: "You deserve a break this summer. Invite your colleagues and let your boss know that you support the campaign for an industry-wide week off.",
            start: new Date('2023-07-03T09:00:00'),
            end: new Date('2023-07-07T17:00:00')
    }

    const googleCalendar = new GoogleCalendar(options).render();
    const outlookCalendar = new OutlookCalendar(options).render();
    const icalendar = new ICalendar(options);

    return (
        <>
            <div className="blockwrap home top">
                <div className="container">
                    <div className="content">
                        
                        {/* <h1 className="title-logo">Independent’s <br />Week<sup>&reg;</sup></h1> */}
                        <h1 className="title-logo"><IndependentsWeekLogo /></h1>

                        {/* <h2 className='subhead'>Send an invite <br className="mobile"/>to <br className="desktop"/>Independents <br className="mobile" />Week 2023</h2> */}
                        {/* <h2 className='subhead'>Add Independent’s<br className="mobile" /> Week 2023 <br className="desktop" /> to your<br className="mobile" /> calendar and invite <br />your coworkers. </h2> */}
                        <h2 className='subhead'>Add Independent’s Week 2023<br className="desktop" /> to your calendar.</h2>
                        <div className="buttons-wrap">
                            <a href={googleCalendar} className="calendar-option" target="_blank" rel="noopener noreferrer" ><CalendarIcon />Google Cal</a>
                            <a href={outlookCalendar} className="calendar-option" target="_blank" rel="noopener noreferrer" ><CalendarIcon  />Outlook</a>
                            <div onClick={() => icalendar.download("IndependentsWeek.ics")} className="calendar-option" ><CalendarIcon  />Apple Mail</div>
                        </div>
                       
                    </div>

                
                </div>

            </div>

            <div className="blockwrap share-block">

                <div className="container">
                    <div className="content">
                        <h3>Tell your network. <br /> Let’s get the industry on<br className="mobile" /> board for some<br className="desktop" /> much<br className="mobile" /> needed time off.</h3>
                        {/* <h3>Share with your network <br className='mobile' /> so we get even more<br /> people onboard.</h3> */}
                        <div className="options-wrap">

                            <div className="option ">
                                <span>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </span>
                                <h4>Let’s take a break this summer.</h4>
                                <p>Burnout is bad for business. That’s why we’re taking a week off during summer from now on. Think your agency could use the break? Go to independentsweek.co to add it to your calendar.</p>
                                
                                <div className="btn-wrap">
                                    <LinkedinShareButton 
                                        resetButtonStyle={false}
                                        className="btn-linkedin" 
                                        url="https://independentsweek.co" 
                                        title="Let's take a break this summer." 
                                        summary="Burnout is bad for business. That's why we're taking a week off during summer from now on. Think your agency could use the break? Go to independentsweek.co to add it to your calendar." 
                                        source="https://independentsweek.co"
                                    >
                                        <Linkedin /> Post
                                    </LinkedinShareButton>
                                    <a className="btn-twitter" href={`https://twitter.com/intent/tweet?text=${tweetCopy1}`} target="_blank" rel="noopener noreferrer" >
                                        <Twitter /> Tweet
                                    </a>
                                </div>
                                
                            </div>

                            <div className="option ">
                                <span>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </span>
                                <h4>The ad industry deserves a summer break.</h4>
                                <p>That’s why I support the campaign for an industry-wide week off. People do better work when they’re rested and recharged. Go to independentsweek.co to add a break to your calendar.</p>
                                
                                <div className="btn-wrap">
                                    {/* <a className="btn-linkedin">
                                        <Linkedin /> Post
                                    </a> */}
                                    <LinkedinShareButton 
                                        resetButtonStyle={false}
                                        className="btn-linkedin" 
                                        url="https://independentsweek.co" 
                                        title="The ad industry deserves a summer break." 
                                        summary="That's why I support the campaign for an industry-wide week off. People do better work when they're rested and recharged. Go to independentsweek.co to add a break to your calendar." 
                                        source="https://independentsweek.co"
                                    >
                                        <Linkedin /> Post
                                    </LinkedinShareButton>
                                    <a className="btn-twitter" href={`https://twitter.com/intent/tweet?text=${tweetCopy2}`} target="_blank" rel="noopener noreferrer" >
                                        <Twitter /> Tweet
                                    </a>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
    
            </div>

           
            <VideoBlock {...props} />
           

            <div className="blockwrap footer">

                <div className='social-wrap'>
                    <a href="https://rethinkideas.com" target="_blank" rel="noopener noreferrer"><RethinkLogo /></a>   
                </div>
                                    
            </div>
        </>
    )
}
export default Home;