import React from 'react';
import YouTube from 'react-youtube';
import './style.scss';

const VideoBlock = (props) => {

    // Youtube options
    const opts = {
        height: ("100%"),
        width: ("100%"),
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        }
    };

    const _onReady = (event) => {
        // access to player in all event handlers via event.target
      
        //setThePlayer(event.target);
        //var thePlayer = event.target;
        //event.target.pauseVideo();
    }

    return (

        <div className="blockwrap video">
            <div className="container">
                <div className="content">

                    <YouTube
                        videoId={'ZMCfeJGQ3Cs'}
                        opts={opts}
                        onReady={_onReady} className="video-wrap" type="video/mp4" 
                    />

                </div>
            </div>
        </div>
    )
}
export default  VideoBlock;