import React from 'react';
import './style.scss';

const CalendarIcon = (props) => {

    return (
/* <svg id="Layer_1" className="btn-social" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.37 75.38">
            <defs></defs>
            <path  d="M404.47,58.16H342.79A6.85,6.85,0,0,0,335.94,65v61.68a6.85,6.85,0,0,0,6.85,6.85h34.27V102.7H366.78V92.42h10.28V86.9c0-10.45,5.09-15,13.78-15a50.25,50.25,0,0,1,7.4.45v9.83h-5.93c-3.69,0-5,2-5,5.89v4.39h10.81l-1.47,10.28h-9.34v30.84h17.13a6.85,6.85,0,0,0,6.85-6.85V65A6.82,6.82,0,0,0,404.47,58.16Z" transform="translate(-335.94 -58.16)" />
        </svg> */
    // <svg   version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" >
    //     <g>
    //         <g>
    //         <line class="st0" x1="617.77" y1="600.57" x2="617.77" y2="597.29"/>
    //         <line class="st0" x1="635.15" y1="600.62" x2="635.15" y2="597.34"/>
    //         </g>
    //         <g>
    //         <path class="st1" d="M636.9,625.68h-20.89c-1.61,0-2.91-1.3-2.91-2.91v-19.43c0-1.61,1.3-2.91,2.91-2.91h20.89
    //             c1.61,0,2.91,1.3,2.91,2.91v19.43C639.81,624.37,638.51,625.68,636.9,625.68z"/>
    //         <line class="st1" x1="613.2" y1="607.42" x2="639.72" y2="607.42"/>
    //         <g>
    //             <line class="st1" x1="621.95" y1="616.14" x2="630.96" y2="616.14"/>
    //             <line class="st1" x1="626.46" y1="620.64" x2="626.46" y2="611.63"/>
    //         </g>
    //         </g>
    //     </g>
    // </svg>
    // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	//  viewBox="0 0 31.06 32.19" style="enable-background:new 0 0 31.06 32.19;" xml:space="preserve">
 <svg version="1.1" className="icon-calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.06 32.19" >
        <g>
            <g>
                <line className="st0" x1="6.71" y1="4.73" x2="6.71" y2="1.45"/>
                <line className="st0" x1="24.09" y1="4.78" x2="24.09" y2="1.5"/>
            </g>
            <g>
                <path className="st0" d="M25.84,29.84H4.95c-1.61,0-2.91-1.3-2.91-2.91V7.5c0-1.61,1.3-2.91,2.91-2.91h20.89
                    c1.61,0,2.91,1.3,2.91,2.91v19.43C28.75,28.53,27.45,29.84,25.84,29.84z"/>
                <line className="st0" x1="2.14" y1="11.58" x2="28.66" y2="11.58"/>
                <g>
                    <line className="st0" x1="10.89" y1="20.3" x2="19.9" y2="20.3"/>
                    <line className="st0" x1="15.4" y1="24.8" x2="15.4" y2="15.79"/>
                </g>
            </g>
        </g>
        </svg>

    )
}
export default CalendarIcon;